import { APPLE_APP_STORE, GOOGLE_PLAY_STORE } from "lib/public-urls";
import Image from "next/image";
import AppStoreImage from "public/Apple Badge@5x.png";
import PlayStoreImage from "public/Google Store Badge@5x.png";
import { analyticsNames, sendAnalyticsEvent } from "../lib/analytics";

export const StoreButtons = () => (
  <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-2">
    <a
      href={APPLE_APP_STORE}
      className="store-button-close sm:mb-0"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => sendAnalyticsEvent(analyticsNames.app_store_button)}
    >
      <Image
        src={AppStoreImage}
        alt="Download on the App Store"
        width={599 / 3}
        height={200 / 3}
        layout="intrinsic"
      />
    </a>
    <a
      href={GOOGLE_PLAY_STORE}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => sendAnalyticsEvent(analyticsNames.play_store_button)}
    >
      <Image
        src={PlayStoreImage}
        alt="Download on Google Play"
        width={675 / 3}
        height={200 / 3}
        layout="intrinsic"
      />
    </a>
  </div>
);
