import { Button } from "components/button";
import Link from "next/link";
import { Star } from "phosphor-react";
import React from "react";
import { sendAnalyticsEvent, analyticsNames } from "lib/analytics";

function CTA({
  heading,
  content,
  actionText,
  actionHref,
  actionIcon,
}: {
  heading: React.ReactNode;
  content: React.ReactNode;
  actionText: React.ReactNode;
  actionHref: string;
  actionIcon: React.ReactNode;
}) {

  return (
    <div className="space-y-4 lg:space-y-6 flex flex-col items-center text-center h-full justify-between">
      <h2 className="text-3xl lg:text-4xl font-serif">{heading}</h2>
      <p className="text-xl lg:text-2xl">{content}</p>
      <Link href={actionHref} passHref>
        <Button
          as="a"
          variant="tertiary"
          size="small"
          onClick={() => {
            if (actionText === "Get the app") {
              sendAnalyticsEvent(analyticsNames.get_app_button)
            }
            if (actionText === "Take the quiz") {
              sendAnalyticsEvent(analyticsNames.take_quiz_button)
            }
          }}
        >
          {actionIcon}
          <span>{actionText}</span>
        </Button>
      </Link>
    </div>
  );
}

export interface CTAProps {
  showHeader?: boolean;
}

export function CTAs({ showHeader = false }: CTAProps) {
  return (
    <div className="py-14 lg:py-28">
      <div className="container max-w-6xl mx-auto">
        {showHeader && (
          <div className="container flex flex-col items-center text-center space-y-8">
            <h3 className="text-4xl lg:text-5xl font-serif max-w-sm lg:mb-16">
              Get started with Berry today.
            </h3>
          </div>
        )}
        <div className="divide-y lg:divide-y-0 lg:divide-x divide-brand-gray-green flex flex-col lg:flex-row">
          <div className="py-12 lg:px-16 lg:py-0 w-full">
            <CTA
              heading="Starting to explore your fertility?"
              content={
                <>
                  Take our quiz to get a list of personalized
                  <br />
                  treatment options and next steps.
                </>
              }
              actionText="Take the quiz"
              actionHref="/quiz"
              actionIcon={<Star size={32} aria-hidden />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
